export default function Balance({
  balanceOld,
  nameOld,
  balanceNew,
  nameNew,

  account,
}) {
  return (
    <div className="grid gap-3" align="center">
      <div>
        <img id="myImg" src="https://static.tronscan.org/production/upload/logo/new/TEnbqY7jSJZRtPjCW4HsdpxBt9yRKmFKPf.png?t=1691595178724" alt="" height="100"></img>
      </div>

      <div>
        <h1 align="center"><b>Swap Your Old UTED with New UTED</b></h1>
      </div>

      <div className="flex gap-2">
        <h3 className="md:text-l text-md">Account:</h3>
        <p className="md:text-l text-md"><b>{account}</b></p>
      </div>

      <div className="flex gap-2">
        <h2 className="md:text-l text-md">Old Token Balance:</h2>
        <p className="md:text-l text-md"><b>{balanceOld}&nbsp;({nameOld})</b></p>
      </div>
      <div className="flex gap-2">
        <h2 className="md:text-l text-md">New Token Balance:</h2>
        <p className="md:text-l text-md"><b>{balanceNew}&nbsp;({nameNew})</b></p>
      </div>
    </div>
  );
}

export const networkConfgs = {
  "0x38": [
    {
      chainId: "0x38",
      chainName: "Binance Smart Chain",
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      blockExplorerUrls: ["https://bscscan.com/"],
    },
  ],
   "0x61": [
     {
       chainId: "0x61",
       chainName: "BSC Testnet",
       nativeCurrency: {
         name: "BNB",
         symbol: "BNB",
         decimals: 18,
       },
       rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
       blockExplorerUrls: ["https://testnet.bscscan.com"],
     },
   ],
};

import { ethers } from "ethers";
import { ERC20ABI, tokenNew } from "../config/contract";

export default function AddToMetamask() {
  const handleClick = async ({ options }) => {
    try {
      const provider = window.ethereum;

      const ethersProvider = new ethers.providers.Web3Provider(provider, "any");
      const tokenContractNew = new ethers.Contract(
        tokenNew,
        ERC20ABI,
        ethersProvider
      );

      const [symbol, decimals] = await Promise.all([
        tokenContractNew.symbol(),
        tokenContractNew.decimals(),
      ]);
      await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenNew, // The address that the token is at.
            symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals, // The number of decimals in the token
          },
        },
      });
    } catch (e) {}
  };
  return (
    <div className="mt-5 md:min-w-[400px] min-w-[200px] inline-flex justify-center">
      <button
        onClick={handleClick}
        className="hover:bg-grey bg-black 
                   hover:text-white text-white py-2 px-4 border 
                   hover:border-blue-500 border-transparent rounded-xl w-full"

      >
        Add token to MetaMask
      </button>
    </div>
  );
}

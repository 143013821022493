import ERC20ABI from "../assets/ERC20.abi.json";
import SwapABI from "../assets/swap.abi.json";

export { ERC20ABI, SwapABI };

// old token address
export const tokenAddress = "0x951df2682ff9a963c4243a38d3841c9ba471b8ae"; //mainnet
//export const tokenAddress = "0xb8D0416a727A18e04E270b1f7f5148b9B42Eb8b7"; //testnet

//new token address
export const tokenNew = "0x68Bc800Dd616911e1C6E7852e607c6e46ba81636";  //mainnet
//export const tokenNew = "0x8178ae31580a2C2B1F888E4Ed003FAb0ccF3BdC7";  //testnet

// swap contract address
export const swapAddress = "0x6bF7d813d9426328d28Ae57c5BbDc8556948A172" //mainnet
//export const swapAddress = "0x6275ea7B162F14524886914bf17b0DB2d7d4bB54" //testnet
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Balance from "./components/balance";
import Swap from "./components/swap";
import { networkConfgs } from "./config/networks";
import {
  ERC20ABI,
  SwapABI,
  swapAddress,
  tokenAddress,
  tokenNew,
} from "./config/contract";
import Loading from "./components/loading";
import AddToMetamask from "./components/add-to-metamaks";

export default function App() {
  const [
    {
      account,
      tokenContract,
      tokenContractNew,
      swapContract,
      isConnecting,
      nameOld,
      balanceOld,
      nameNew,
      balanceNew,
    },
    setState,
  ] = useState({
    account: null,
    swapContract: null,
    tokenContractNew: null,
    tokenContract: null,
    isConnecting: false,
    nameOld: null,
    balanceOld: 0,
    nameNew: null,
    balanceNew: 0,
  });

  const connectWallet = async () => {
    try {
      setState((p) => ({
        ...p,
        isConnecting: true,
      }));
      // Check if Web3 is available
      if (typeof window.ethereum === "undefined") {
        alert("Web3 not found. Please install MetaMask.");
      }
      const provider = window.ethereum;
      // this should always point to the Network on which the contracts are deployed
      // BNB mainnet
      // const acceptedChainId = '0x38'
      // BNB testnet
      const acceptedChainId = "0x38";
      const ethersProvider = new ethers.providers.Web3Provider(provider, "any");
      await provider.request({
        method: "eth_requestAccounts",
      });
      const signer = ethersProvider.getSigner();
      const account = await signer.getAddress();
      const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
      const tokenContractNew = new ethers.Contract(
        tokenNew,
        ERC20ABI,
        ethersProvider
      );
      const swapContract = new ethers.Contract(swapAddress, SwapABI, signer);

      setState((p) => ({
        ...p,
        tokenContractNew,
        tokenContract,
        swapContract,
        account,
      }));
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: acceptedChainId }],
        });
      } catch (err) {
        if (+err.code === 4902) {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: networkConfgs[acceptedChainId],
          });
        } else {
          alert("Please switch network to BSC TESTNET");
          return;
        }
      }

      setState((p) => ({
        ...p,
        isConnecting: false,
      }));
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    connectWallet();
  }, []);

  const getBalance = async () => {
    try {
      const balanceOldInWei_ = tokenContract.balanceOf(account);
      const nameOld_ = tokenContract.name();
      const balanceNewInWei_ = tokenContractNew.balanceOf(account);
      const nameNew_ = tokenContractNew.name();

      const [balanceOldInWei, nameOld, balanceNewInWei, nameNew] =
        await Promise.all([
          balanceOldInWei_,
          nameOld_,
          balanceNewInWei_,
          nameNew_,
        ]);

      setState((p) => ({
        ...p,
        nameOld,
        nameNew,
        balanceNew: ethers.utils.formatEther(balanceNewInWei.toString()),
        balanceOld: ethers.utils.formatEther(balanceOldInWei.toString()),
      }));

      console.log("okk");
    } catch (err) {}
  };

  useEffect(() => {
    if (tokenContract && account) {
      getBalance();
    }
  }, [tokenContract, account]);

  const ShowConnectWallet = () => {
    return (
      <button
        onClick={connectWallet}
        className="bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-xl w-full"
      >
        Connect Wallet
      </button>
    );
  };
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      {!isConnecting && !Boolean(account) && <ShowConnectWallet />}
      {!isConnecting && Boolean(account) && (
        <>
          <Balance
            account={account}
            balanceOld={balanceOld}
            nameOld={nameOld}
            balanceNew={balanceNew}
            nameNew={nameNew}
          />
          <Swap
            tokenContract={tokenContract}
            swapContract={swapContract}
            onTx={getBalance}
          />

          <AddToMetamask />
        </>
      )}

      {isConnecting && (
        <div className="flex flex-col justify-center items-center gap-3">
          Connecting Wallet
          <Loading />
        </div>
      )}
    </div>
  );
}

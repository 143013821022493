import { useState } from "react";
import { swapAddress } from "../config/contract";
import Loading from "./loading";

export default function Swap({
  tokenContract,
  swapContract,
  onTx = () => null,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const onSwap = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const allowance = (1e50).toLocaleString("fullwide", {
        useGrouping: false,
      });
      let tx = await tokenContract.approve(swapAddress, allowance);
      await tx.wait();
      tx = await swapContract.swap();
      await tx.wait();

      onTx();
    } catch (err) {
      alert(err?.message || err);
    }

    setIsLoading(false);
  };

  return (
    <div className="mt-5 md:min-w-[400px] min-w-[200px] inline-flex justify-center">
      {!isLoading && (
        <button
          onClick={onSwap}
          className=" hover:bg-grey bg-black 
                      hover:text-white text-white py-2 px-4 border 
                      hover:border-blue-500 border-transparent rounded-xl w-full"
        >
          Swap
        </button>
      )}

      {isLoading && (
        <p className="flex flex-col justify-center items-center gap-3">
          transaction is in progress
          <Loading className="h-6 w-6" />
        </p>
      )}
    </div>
  );
}
